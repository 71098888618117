<!-- 用户编辑弹窗 -->
<template>
  <a-modal
    :width="680"
    :visible="visible"
    :confirm-loading="loading"
    :title="isUpdate ? '修改举荐' : '新建举荐'"
    :body-style="{ paddingBottom: '8px' }"
    @update:visible="updateVisible"
    @ok="save"
  >
    <a-form
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{ md: { span: 7 }, sm: { span: 24 } }"
      :wrapper-col="{ md: { span: 17 }, sm: { span: 24 } }"
    >
      <a-row :gutter="16">
        <a-col :md="12" :sm="24" :xs="24">
          <a-form-item label="举荐人:" name="name">
            <a-input
              allow-clear
              placeholder="举荐人"
              v-model:value="form.name"
            />
          </a-form-item>
          <a-form-item label="举荐人身份证:" name="idcard">
            <a-input
              allow-clear
              :maxlength="18"
              placeholder="请输入举荐人身份证"
              v-model:value="form.idcard"
            />
          </a-form-item>
          <a-form-item label="事件地点:" name="address">
            <a-input
              allow-clear
              placeholder="请输入事件地点"
              v-model:value="form.address"
            />
          </a-form-item>
        </a-col>
        <a-col :md="12" :sm="24" :xs="24">
          <a-form-item label="举荐人手机号:" name="phone">
            <a-input
              allow-clear
              :maxlength="11"
              placeholder="请输入举荐人手机号"
              v-model:value="form.phone"
            />
          </a-form-item>
          <a-form-item label="事件日期:" name="time">
            <a-date-picker
              class="ud-fluid"
              value-format="YYYY-MM-DD"
              placeholder="请选择事件日期"
              v-model:value="form.time"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :md="24" :sm="24" :xs="24" style="height: 150px">
          详细事件描述:
          <a-textarea
            allow-clear
            type="textarea"
            placeholder="详细事件描述"
            v-model:value="form.description"
            style="width: 100%; height: 120px"
          >
          </a-textarea>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :md="24" :sm="24" :xs="24">
          相关证明材料上传
          <a-upload
            list-type="picture-card"
            v-model:file-list="fileList"
            @preview="handlePreview"
            :customRequest="({ file }) => uploadFile(file, 'idcardPic')"
            :remove="removeFile"
          >
            <div v-if="fileList.length < 1">
              <plus-outlined />
              <div class="ant-upload-text">上传</div>
            </div>
          </a-upload>
          <a-modal
            :visible="previewVisible"
            :footer="null"
            @cancel="handleCancel"
            :bodyStyle="{ textAlign: 'center',  paddingTop: '40px', width: '100%', overflow: 'auto' }"
          >
            <img alt="example" style="width: 100%" :src="previewImage" />
          </a-modal>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
import setting from '@/config/setting'
import * as recommendApi from '@/api/jyyw/recommend'
function getBase64(pointPhoto) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(pointPhoto)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}
export default {
  name: 'recommendEdit',
  emits: ['done', 'update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      fileList: [],
      heros: [
        {
          name: '',
          idcard: ''
        }
      ],
      uploadUrl: setting.uploadUrl,
      // 表单验证规则
      rules: {},
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false
    }
  },
  watch: {
    data() {
      if (this.data) {
        this.form = Object.assign({}, this.data)
        if (this.form.heros) {
          this.heros = JSON.parse(this.form.heros)
        }
        if (this.form.attachment) {
          const items = JSON.parse(this.form.attachment)
          // eslint-disable-next-line no-unused-vars
          let i = 0
          this.fileList = items.map((item) => {
            i++
            return {
              uid: '-2',
              name: 'image.png',
              status: 'done',
              url: item
            }
          })
          console.log(items)
        }
        this.isUpdate = true
      } else {
        this.form = {}
        this.isUpdate = false
      }
      if (this.$refs.form) {
        this.$refs.form.clearValidate()
      }
    }
  },
  mounted() {},
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs.form
        .validate()
        .then(() => {
          this.loading = true
          recommendApi.save(this.form, this.isUpdate)
            .then((res) => {
              this.loading = false
              if (res.data.code === 0) {
                this.$message.success(res.data.msg)
                if (!this.isUpdate) {
                  this.form = {}
                }
                this.updateVisible(false)
                this.$emit('done')
              } else {
                this.$message.error(res.data.msg)
              }
            })
            .catch((e) => {
              this.loading = false
              this.$message.error(e.message)
            })
        })
        .catch(() => {})
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    },
    removeFile(file) {
      console.log(file)
      this.$http
        .delete('/file/remove', {
          path: file.url
        })
        .then((res) => {
          if (res.data.code === 0) {
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((err) => {
          this.$message.error(err)
        })
    },
    uploadFile(file, name) {
      const formData = new FormData()
      formData.append('file', file)
      const hide = this.$message.loading('上传中..', 0)
      this.$http
        .post(this.uploadUrl, formData)
        .then((res) => {
          hide()
          if (res.data.code === 0) {
            if (name === 'pointPhoto') {
              this.pointPhotoList[0] = {
                uid: '-4',
                name: 'image.png',
                status: 'done',
                url: res.data.location
              }
            } else if (name === 'businessLicense') {
              this.businessLicenseList[0] = {
                uid: '-3',
                name: 'image.png',
                status: 'done',
                url: res.data.location
              }
            } else if (name === 'idcardPic') {
              this.idcardPicList[0] = {
                uid: '-2',
                name: 'image.png',
                status: 'done',
                url: res.data.location
              }
            } else if (name === 'idcardPic2') {
              this.idcardPic2List[0] = {
                uid: '-1',
                name: 'image.png',
                status: 'done',
                url: res.data.location
              }
            }
            this.form[name] = res.data.location
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
      return false
    },
    async handlePreview(pointPhoto) {
      if (!pointPhoto.url && !pointPhoto.preview) {
        pointPhoto.preview = await getBase64(pointPhoto.originFileObj)
      }
      this.previewImage = pointPhoto.url || pointPhoto.preview
      this.previewVisible = true
    },
    handleChange({ fileList }) {
      this.fileList = fileList
    },
    handleCancel() {
      this.previewVisible = false
    }
  }
}
</script>

<style scoped>
</style>
