import request from '@/utils/request'

// api地址
const api = {
  all: '/jyyw/heroRecommend',
  save: '/jyyw/heroRecommend',
  update: 'jyyw/heroRecommend/update',
  info: '/jyyw/heroRecommend/',
  delete: '/jyyw/heroRecommend/',
  deleteBatch: '/jyyw/heroRecommend/batch',
  resultDictdata: '/sys/dictdata',
  page: '/jyyw/heroRecommend/page',
  forwarded: '/jyyw/heroRecommend/forwarded',
  agree: '/jyyw/heroRecommend/agree',
  refuse: '/jyyw/heroRecommend/refuse',
  download: '/jyyw/heroRecommend/download/',
  downloadBatch: '/jyyw/heroRecommend/download/batch',
  saveComment: '/jyyw/heroRecommend/comment'
}

/**
 * 根据id查询
 * @param id id
 * @returns {Promise<Object>}
 */
export function getById(id) {
  return new Promise((resolve, reject) => {
    request.get(api.info + id).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 查询列表
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function page(params) {
  return new Promise((resolve, reject) => {
    request.get(api.page, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 查询列表
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function all(params) {
  return new Promise((resolve, reject) => {
    request.get(api.all, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 根据id删除
 * @param id id
 * @returns {Promise<>}
 */
export function deleteById(id) {
  return new Promise((resolve, reject) => {
    request.delete(api.delete + id).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 批量删除
 * @param id id
 * @returns {Promise<>}
 */
export function deleteBatch(data) {
  return new Promise((resolve, reject) => {
    request.delete(api.deleteBatch, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 添加或修改
 * @param data 参数
 * @returns {Promise<>}
 */
export function save(data, isUpdate) {
  if (isUpdate) {
    return new Promise((resolve, reject) => {
      request.post(api.update, data).then(res => {
        resolve(res.data)
      }).catch(e => {
        reject(e)
      })
    })
  } else {
    return new Promise((resolve, reject) => {
      request.post(api.save, data).then(res => {
        resolve(res.data)
      }).catch(e => {
        reject(e)
      })
    })
  }
}
/**
 * 查询列表
 * 分页查询申请举荐结果字典项
 * @param params 参数
 * @returns {Promise<Array>}
 */
export const resultDictdata = (val) => {
  return new Promise((resolve, reject) => {
    const params = { dictCode: val }
    request.get(api.resultDictdata, { params: params }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 转发材料
 * @param data
 * @returns  {Promise<Array>}
 */
export function forwarded(data) {
  return new Promise((resolve, reject) => {
    request.post(api.forwarded, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 行为确认
 * @param data
 * @returns  {Promise<Array>}
 */
export function agree(data) {
  return new Promise((resolve, reject) => {
    request.post(api.agree, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 行为不予确认
 * @param data
 * @returns  {Promise<Array>}
 */
export function refuse(data) {
  return new Promise((resolve, reject) => {
    request.post(api.refuse, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 根据id下载
 * @param id id
 * @returns {Promise<>}
 */
export function download(id) {
  return new Promise((resolve, reject) => {
    request.get(api.download + id).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 导出
 * @param id id
 * @returns {Promise<>}
 */
export function downloadBatch(data) {
  return new Promise((resolve, reject) => {
    request.post(api.downloadBatch, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

export function saveComment(data) {
  return new Promise((resolve, reject) => {
    request.post(api.saveComment, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
